import { Link } from "react-router-dom";

import './notificationAndSearch.css';
import wifi from '../images/wifi.png';
import alerts from '../images/alerts-image-1.png';
import dashboard from '../images/dashboard.png';
// import nas1 from '../images/nas_1.png';
// import nas2 from '../images/nas_2.png';
// import nas3 from '../images/nas_3.png';

const NotificationAndSearch = () => {

    return (
        <div className="notificationAndSearch">
            <div className='nas-top'>
                <div className='nas-content'>
                    <div className='title-container'>
                        <h1>Stay Informed</h1>
                        <img className='wifi' src={wifi} alt="Wifi logo"/>
                    </div>
                    <h3>Customization Notifications</h3>
                    <p>Violerts scans official databases and notifies you the moment a new violation is issued for your property. No more missed deadlines or scrambling for information</p>
                </div>
                <div className='nas-content'>
                    <Link to="/property/130%20West%2037%20Street%2C%20New%20York%2C%20NY%2010018/overview"><img src={alerts} alt='Violerts Alerts'/></Link>
                </div>
            </div>
            <div className='nas-top inverted'>
                <div className='nas-content'>
                    <div className='title-container'>
                        <h1>Deep Records Search</h1>
                        <img className='wifi' src={wifi} alt="Wifi logo"/>
                    </div>

                    <p>Violerts cuts through the noise by combing public databases and historical records to deliver a comprehensive background of your property, uncovering potential issues and empowering informed decisions.</p>
                    {/*<h3 style={{textAlign:'center' }}>Powerful Search Integrations</h3>
                    <div className='nas-icon-container' >
                        <a href="">
                            <img src={nas1} alt='NAS 1'/>
                        </a>
                        <a href="">
                            <img src={nas2} alt='NAS 2'/>
                        </a>
                        <a href="">
                            <img src={nas3} alt='NAS 3'/>
                        </a>

                    </div>*/}
                </div>
                <div className='nas-content'>
                    <Link to="/property/130%20West%2037%20Street%2C%20New%20York%2C%20NY%2010018/overview"><img src={dashboard} alt='Violerts Dashboard'/></Link>
                </div>
            </div>

        </div>
    );
}

export default NotificationAndSearch;

