import { OPEN_DATA, BIS, CITYPAY, NYC_GOV } from 'data/dataSourceConstants';

export const tableConfig = {
	complaints311: {
		title: '311 Complaints',
		singularTitle: '311 Complaint',
		grouping: 'Complaints',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date', key: 'created_date', formatter: 'DateFormatter' },
				{ label: 'Description', key: 'descriptor' },
				{ label: 'Resolution', key: 'resolution_description' },
				{ label: 'Agency', key: 'agency' },
				{ label: 'Agency Name', key: 'agency_name' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	dobComplaints: {
		title: 'DOB Complaints',
		singularTitle: 'DOB Complaint',
		grouping: 'Complaints',
		dataCategory: 'violation',
		[BIS]: {
			columns: [
				{ label: 'Date', key: 'date_entered', formatter: 'DateFormatter' },
				{ label: 'Complaint #', key: 'complaint_number' },
				{ label: 'Comments', key: 'comments' },
				{ label: 'Category', key: 'category' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
		[OPEN_DATA]: {
			columns: [
				{ label: 'Complaint #', key: 'complaint_number' },
				{ label: 'Date Entered', key: 'date_entered', formatter: 'DateFormatter' },
				{ label: 'Inspection Date', key: 'inspection_date', formatter: 'DateFormatter' },
				{ label: 'Disposition Date', key: 'disposition_date', formatter: 'DateFormatter' },
				{ label: 'Disposition', key: 'disposition' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	dobViolations: {
		title: 'DOB Violations',
		singularTitle: 'DOB Violation',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Violation #', key: 'number' },
				{ label: 'Issue Date', key: 'issue_date', formatter: 'DateFormatter' },
				{ label: 'Disposition Date', key: 'disposition_date', formatter: 'DateFormatter' },
				{ label: 'Disposition Comments', key: 'disposition_comments' },
				{ label: 'Violation Type', key: 'violation_type' },
				{ label: 'Violation Category', key: 'violation_category', formatter: 'StatusBadge' },
			],
		},
		[BIS]: {
			columns: [
				{ label: 'Resolve', key: 'dob_violation_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Violation #', key: 'dob_violation_number' },
				{ label: 'Device #', key: 'device_number' },
				{ label: 'ECB Violation #', key: 'ecb_violation_number' },
				{ label: 'Description', key: 'description' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	ecbViolations: {
		title: 'ECB Violations',
		singularTitle: 'ECB Violation',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'ecb_violation_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'AEU2', key: 'ecb_violation_number', formatter: 'AEU2Link' },
				{ label: 'AEU20', key: 'ecb_violation_number', formatter: 'AEU20Link' },
				{ label: 'Date', key: 'issue_date', formatter: 'DateFormatter' },
				{ label: 'Hearing Date', key: 'hearing_date', formatter: 'CalendarLink' }, //adds to google calendar
				{ label: 'ECB #', key: 'ecb_violation_number' },
				{ label: 'Infra. Code', key: 'infraction_code1', formatter: 'ECBInfractionCode' },
				{ label: 'Description', key: 'violation_description' },
				{ label: 'Respondent Name', key: 'respondent_name' },
				{ label: 'Penalty Imposed', key: 'penality_imposed' },
				{ label: 'Status', key: 'ecb_violation_status', formatter: 'StatusBadge' },
			],
		},
		[BIS]: {
			columns: [
				{ label: 'Resolve', key: 'ecb_violation_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'AEU2', key: 'ecb_violation_number', formatter: 'AEU2Link' },
				{ label: 'AEU20', key: 'ecb_violation_number', formatter: 'AEU20Link' },
				{ label: 'ECB #', key: 'ecb_violation_number' },
				{ label: 'Infra. Code', key: 'infraction_codes', formatter: 'ECBInfractionCode' },
				{ label: 'Status', key: 'buildings_violation_status', formatter: 'StatusBadge' },
			],
		},
	},
	jobApplications: {
		// title: 'Job Applications',
		// singularTitle: 'Job Application',
		title: 'Work Applications',
		singularTitle: 'Work Application',
		grouping: 'Job Applications',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Drawings', key: 'job__', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Last Action Date', key: 'latest_action_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job__' },
				{ label: 'First Name', key: 'applicant_s_first_name' },
				{ label: 'Last Name', key: 'applicant_s_last_name' },
				{ label: 'Doc #', key: 'doc__' },
				{ label: 'Status', key: 'job_status_descrp', formatter: 'StatusBadge' }, //wrong field?

			],
		},
		[BIS]: {
			columns: [
				{ label: 'Last Action Date', key: 'status_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job_number' },
				{ label: 'Doc #', key: 'doc_number' },
				{ label: 'Type', key: 'job_type' },
				{ label: 'Applicant', key: 'applicant' },
				{ label: 'Status', key: 'job_status', formatter: 'StatusBadge' }, //wrong field?
			],
		},
	},
	dobPermitIssuances: {
		title: 'DOB Permit Issuances',
		singularTitle: 'DOB Permit Issuance',
		navTitle: 'Permit Issuances',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Issue Date', key: 'issuance_date', formatter: 'DateFormatter' },
				{ label: 'Expiration Date', key: 'expiration_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job__' },
				{ label: 'Job Doc', key: 'job_doc___' },
				{ label: 'Permittee First Name', key: 'permittee_s_first_name' },
				{ label: 'Permittee Last Name', key: 'permittee_s_last_name' },
				{ label: 'Permittee Business', key: 'permittee_s_business_name' },
				{ label: 'Permittee Phone', key: 'permittee_s_phone__' },
				{ label: 'License Type', key: 'permittee_s_license_type' },
				{ label: 'License', key: 'permittee_s_license__' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Permit Status', key: 'permit_status', formatter: 'StatusBadge' },
			],
		},
	},
	approvedPermits: {
		title: 'DOB NOW Build - Approved Permits',
		singularTitle: 'DOB NOW Build - Approved Permit',
		navTitle: 'Approved Permits',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing #', key: 'job_filing_number' },
				{ label: 'Work Permit', key: 'work_permit' },
				{ label: 'Issued Date', key: 'issued_date', formatter: 'DateFormatter' },
				{ label: 'Expiring Date', key: 'expired_date', formatter: 'DateFormatter' },
				{ label: 'Floor', key: 'work_on_floor' },
				{ label: 'Work Type', key: 'work_type' },
				{ label: 'Description', key: 'job_description' },
				{ label: 'First Name', key: 'applicant_first_name' },
				{ label: 'Last Name', key: 'applicant_last_name' },
				{ label: 'Applicant Business', key: 'applicant_business_name' },
				{ label: 'Applicant Address', key: 'applicant_business_address' },
				{ label: 'Estimated Costs', key: 'estimated_job_costs' },
			],
		},
	},
	certificatesOfOccupancy: {
		title: 'DOB Certificates of Occupancy',
		singularTitle: 'DOB Certificate of Occupancy',
		navTitle: 'Certificates of Occupancy',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Issues Date', key: 'c_o_issue_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job_number' },
				{ label: 'Issue Type', key: 'issue_type' },
				{ label: 'Filing Status', key: 'filing_status_raw' },
			],
		},
	},
	dobJobApplications: {
		// title: 'DOB NOW Build - Job Application Filings',
		title: 'DOB NOW Build - Work Application Filings',
		// singularTitle: 'DOB NOW Build - Job Application Filing',
		singularTitle: 'DOB NOW Build - Work Application Filings',
		// navTitle: 'Job Application Filings',
		navTitle: 'Work Application Filings',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing #', key: 'job_filing_number' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Business Name', key: 'owner_s_business_name' },
			],
		},
	},
	dobNowSafetyFacades: {
		title: 'Facade Filings',
		singularTitle: 'Facade Filing',
		grouping: 'Job Applications',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing #', key: 'tr6_no' },
				{ label: 'Control #', key: 'control_no' },
				{ label: 'Filing Type', key: 'filing_type' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Current Status', key: 'current_status' },
				{ label: 'Prior Status', key: 'prior_status' },
				{ label: 'QEWI Name', key: 'qewi_name' },
				{ label: 'QEWI Address', key: 'qewi_bus_street_name' },
				{ label: 'Field Inspection Date', key: 'field_inspection_completed_date', formatter: 'DateFormatter' },
				{ label: 'QEWI Signed Date', key: 'qewi_signed_date', formatter: 'DateFormatter' },
				{ label: 'Submitted Date', key: 'submitted_on', formatter: 'DateFormatter' },
				{ label: 'Late Filing Amount', key: 'late_filing_amt' },
				{ label: 'Failure to File Amount', key: 'failure_to_file_amt' },
				{ label: 'Failure to Collect Amount', key: 'failure_to_collect_amt' },

			],
		},
	},
	dobSignApplicationFilings: {
		title: 'Signage',
		singularTitle: 'Signage',
		// grouping: 'Department of Buildings',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job #', key: 'job__' },
				{ label: 'Job Status', key: 'job_status' },
				{ label: 'Filing Date', key: 'pre_filing_date', formatter: 'DateFormatter' },
			],
		},
	},
	dobNowSafetyBoilers: {
		title: 'Boilers',
		singularTitle: 'Boiler',
		// grouping: 'Department of Buildings',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Boiler ID', key: 'boiler_id' },
				{ label: 'Report Type', key: 'report_type' },
				{ label: 'First Name', key: 'applicantfirst_name' },
				{ label: 'Last Name', key: 'applicant_last_name' },
				{ label: 'License Type', key: 'applicant_license_type' },
				{ label: 'License #', key: 'applicant_license_number' },
				{ label: 'Inspection Date', key: 'inspection_date', formatter: 'DateFormatter' },
				{ label: 'Report Status', key: 'report_status' },
			],
		},
	},
	electricalPermitApplications: {
		title: 'Electrical',
		singularTitle: 'Electrical',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing Number', key: 'job_filing_number' },
				{ label: 'Filing Date', key: 'filing_date', formatter: 'DateFormatter' },
				{ label: 'Start Date', key: 'job_start_date', formatter: 'DateFormatter' },
				{ label: 'Completion Date', key: 'completion_date', formatter: 'DateFormatter' },
				{ label: 'Job Description', key: 'job_description' },
				{ label: 'First Name', key: 'applicant_first_name' },
				{ label: 'Last Name', key: 'applicant_last_name' },
				{ label: 'Firm', key: 'firm_name' },
				{ label: '10 Pt', key: '_10_points' },
				{ label: 'Amount Paid', key: 'amount_paid' },
				{ label: 'Amount Due', key: 'amount_due' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Job Status', key: 'job_status' },
			],
		},
	},
	elevatorPermitApplications: {
		title: 'Elevators',
		singularTitle: 'Elevator',
		// grouping: 'Department of Buildings',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing Number', key: 'job_filing_number' },
				{ label: 'Job Number', key: 'job_number' },
				{ label: 'Filing Date', key: 'filing_date', formatter: 'DateFormatter' },
				{ label: 'Filing Type', key: 'filing_type' },
				{ label: 'Filing Status', key: 'filing_status' },
			],
		},
	},
	hpdHousingViolations: {
		title: 'Housing Violations',
		singularTitle: 'Housing Violation',
		grouping: 'HPD',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'violationid', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Violation ID', key: 'violationid' },
				{ label: 'Issue Date', key: 'novissueddate', formatter: 'DateFormatter' },
				{ label: 'Description', key: 'novdescription' },
				{ label: 'Status', key: 'currentstatus', formatter: 'StatusBadge' },
			],
		},
	},
	// hpdHousingComplaints: {
	// 	title: 'Housing Complaints',
	// 	singularTitle: 'Housing Complaint',
	// 	grouping: 'HPD',
	// 	dataCategory: 'violation',
	// 	[OPEN_DATA]: {
	// 		columns: [
	// 			{ label: 'Complaint ID', key: 'complaintid' },
	// 			{ label: 'Date', key: 'receiveddate', formatter: 'DateFormatter' },
	// 			{ label: 'Problems', key: 'statusdescription' }, //todo: this has a custom renderer in legacy vue
	// 			{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
	// 		],
	// 	},
	// },
	hpdComplaints: {
		title: 'Housing Complaints',
		singularTitle: 'Housing Complaint',
		grouping: 'HPD',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Complaint ID', key: 'complaint_id' },
				{ label: 'Date', key: 'received_date', formatter: 'DateFormatter' },
				// { label: 'Type', key: 'type' },
				{ label: 'Apt #', key: 'apartment' },
				{ label: 'Category', key: 'major_category' },
				{ label: 'Condition', key: 'minor_category' },
				{ label: 'Detail', key: 'problem_code' },
				{ label: 'Location', key: 'space_type' },
				// { label: 'Description', key: 'status_description' },
				{ label: 'Status', key: 'complaint_status', formatter: 'StatusBadge' },
			],
		},
	},
	repairVacateOrders: {
		title: 'HPD Orders to Repair/Vacate',
		singularTitle: 'HPD Order to Repair/Vacate',
		navTitle: 'Orders to Repair/Vacate',
		grouping: 'HPD',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'vacate_order_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Order Number', key: 'vacate_order_number' },
				{ label: 'Date', key: 'vacate_effective_date', formatter: 'DateFormatter' },
				{ label: 'Type', key: 'vacate_type' },
				{ label: 'Reason', key: 'primary_vacate_reason' },
				{ label: 'Units Vacated', key: 'number_of_vacated_units' },
			],
		},
	},
	standardsApplicationStatuses: {
		title: 'Board of Standards and Appeals',
		singularTitle: 'Board of Standards and Appeals',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date Filed', key: 'filed', formatter: 'DateFormatter' },
				{ label: 'Status', key: 'status' },
				{ label: 'Project Description', key: 'project_description' },
			],
		},
	},
	fdnyViolations: {
		title: 'FDNY - Active Violation Orders',
		singularTitle: 'FDNY - Active Violation Order',
		navTitle: 'FDNY Violation Orders',
		grouping: 'FDNY',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'violation_num', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Date', key: 'vio_date', formatter: 'DateFormatter' },
				{ label: 'Violation ID', key: 'vio_id' },
				{ label: 'Violation Number', key: 'violation_num' },
				{ label: 'Description', key: 'vio_law_desc' },
				{ label: 'Status', key: 'action', formatter: 'StatusBadge' },
			],
		},
	},
	fdnyInspections: {
		title: 'FDNY - Inspections',
		singularTitle: 'FDNY - Inspection',
		navTitle: 'FDNY Inspections',
		grouping: 'FDNY',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date', key: 'last_visit_dt', formatter: 'DateFormatter' },
				{ label: 'Acct ID', key: 'acct_id' },
				{ label: 'Owner Name', key: 'owner_name' },
				{ label: 'Status', key: 'last_insp_stat', formatter: 'StatusBadge' },
			],
		},
	},
	fdnyPermits: {
		title: 'FDNY - Permits',
		singularTitle: 'FDNY - Permit',
		navTitle: 'FDNY Permits',
		grouping: 'FDNY',
		isViolation: true,
		dataCategory: 'violation',
		[NYC_GOV]: {
			columns: [
				{ label: 'Resolve', key: 'entityId', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Date', key: 'displayStatusDate', formatter: 'DateFormatter' },
				{ label: 'Type', key: 'statusAgencyOrType' },
				{ label: 'Account #', key: 'entityId' },
				{ label: 'Category', key: 'statusCategory' },
				{ label: 'PDF', key: 'pdfLink', formatter: 'OutboundLink' },
				{ label: 'Status', key: 'statusCode', formatter: 'StatusBadge' },
			],
		},
	},
	fdnySummaries: {
		navTitle: 'FDNY Summary',
		title: 'FDNY - Summary',
		singularTitle: 'FDNY - Summary',
		grouping: 'FDNY',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Summary Id', key: 'summary_id' },
				{ label: '# Siam Sprinklers', key: 'num_siam_sprinkler' },
				{ label: 'Sprinkler Type', key: 'sprinkler_type' },
				{ label: '# Siam Standpipes', key: 'num_siam_standpipe' },
				{ label: 'Standpipe Type', key: 'standpipe_type' },
				{ label: '# Open Notices of Violation', key: 'num_of_violation' },
				{ label: '# Open Violation Orders', key: 'num_of_violation_1' },
			],
		},
	},
	citypayTickets: {
		title: 'CityPay Tickets',
		singularTitle: 'CityPay Ticket',
		dataCategory: 'violation',
		isViolation: true,
		[CITYPAY]: {
			columns: [
				{ label: 'Resolve', key: 'ticketNumber', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Vio. Date', key: 'violationDate', formatter: 'DateFormatter' },
				{ label: 'Ticket Number', key: 'ticketNumber' },
				{ label: 'Description', key: 'description' },
				{ label: 'Issuing Agency', key: 'issuingAgency' },
				{ label: 'Status', key: 'status' },
				{ label: 'Total Due', key: 'totalAmountDue' },
			],
		},
	},
	taxLienSales: {
		title: 'Tax Lien Sales',
		singularTitle: 'Tax Lien Sale',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date', key: 'month', formatter: 'DateFormatter' },
				{ label: 'Cycle', key: 'cycle' },
				{ label: 'Tax Class Code', key: 'tax_class_code' },
				{ label: 'Building Class', key: 'building_class' },
				{ label: 'Community Board', key: 'community_board' },
				{ label: 'Council District', key: 'council_district' },
			],
		},
	},
	acrisLegals: {
		title: 'ACRIS Legals',
		singularTitle: 'ACRIS Real Property Legal',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Document ID', key: 'document_id', formatter: 'AcrisRealPropertyMaster' },
				{ label: 'Record Type', key: 'record_type', },
				{ label: 'Easement', key: 'easement', },
				{ label: 'Partial Lot', key: 'partial_lot', },
				{ label: 'Air Rights', key: 'air_rights', },
				{ label: 'Subterranean Rights', key: 'subterranean_rights', },
				{ label: 'Property Type', key: 'property_type', },
				{ label: 'Good Through Date', key: 'good_through_date', formatter: 'DateFormatter', },
			],
		},
	},
	dobPlaceOfAssembly: {
		title: 'DOB Place of Assembly',
		singularTitle: 'DOB Place of Assembly',
		dataCategory: 'certification',
		[BIS]: {
			columns: [
				{ label: 'Job #', key: 'jobNumber', },
				{ label: 'File Date', key: 'fileDate', formatter: 'DateFormatter', },
				{ label: 'License Number', key: 'licenseNumber', },
				{ label: 'Applicant', key: 'applicant', },
				{ label: 'Description', key: 'description', },
				{ label: 'PDF', key: 'pdfLink', formatter: 'OutboundLink' },
				{ label: 'Details', key: 'detailsLink', formatter: 'OutboundLink' },
				{ label: 'Job Status', key: 'jobStatus', formatter: 'StatusBadge' },
			],
		},
	},
	slaLiquorLicenses: {
		title: 'SLA Liquor Licenses',
		singularTitle: 'SLA Liquor License',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Legal Name', key: 'legalname' },
				{ label: 'Premises', key: 'actualaddressofpremises' },
				{ label: 'Description', key: 'description' },
				{ label: 'Issued', key: 'lastissuedate', formatter: 'DateFormatter' },
				{ label: 'Effective', key: 'effectivedate', formatter: 'DateFormatter' },
				{ label: 'Expires', key: 'expirationdate', formatter: 'DateFormatter' },
				{ label: 'Class', key: 'class' },
				{ label: 'Type', key: 'type' },
				{ label: 'Permit ID', key: 'licensepermitid', },
				{ label: 'Legacy Serial #', key: 'legacyserialnumber' },
			],
		},
	},
	dosBusinessEntities: {
		title: 'DOS Business Entities',
		singularTitle: 'DOS Business Entity',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Name', key: 'corp_name' },
				{ label: 'Id', key: 'corpid_num' },
				{ label: 'Doc Type', key: 'documenttype' },
				{ label: 'Entity Type', key: 'entitytype' },
				{ label: 'Filed', key: 'date_filed', formatter: 'DateFormatter' },
			],
		},
	},
	deedRestrictions: {
		title: 'Deed Restrictions',
		singularTitle: 'Deed Restriction',
		dataCategory: 'certification',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Link', key: 'deed_lease_easement_link', formatter: 'OutboundLink' },
				{ label: 'Restriction', key: 'restriction' },
			],
		},
	},
	landmarkBuildings: {
		title: 'Landmarks',
		singularTitle: 'Landmark',
		dataCategory: 'infrastructure',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Landmark Name', key: 'lm_new' },
				{ label: 'Building', key: 'build_nme' },
				{ label: 'Built', key: 'date_combo' },
				{ label: 'Architect/Builder', key: 'arch_build' },
				{ label: 'Owner/Developer', key: 'own_devel' },
				{ label: 'Style', key: 'style_prim' },
				{ label: 'Style (secondary)', key: 'style_sec' },
			],
		},
	},
	landmarkViolations: {
		title: 'Landmark Violations',
		singularTitle: 'Landmark Violation',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Violation Date', key: 'vio_date', formatter: 'DateFormatter' },
				{ label: 'Landmark Name', key: 'landmark_name' },
				{ label: 'Violation', key: 'violation_class' },
			],
		},
	},
};


export default tableConfig;
