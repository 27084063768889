import { Link } from "react-router-dom";

import '../notificationAndSearch/notificationAndSearch.css';
import wifi from "../images/wifi.png";
import alerts from '../images/tas_1.png';
import records from '../images/tas_2.png';

const ToolsAndCompliance = () => {

    return (
        <div className="notificationAndSearch">
            <div className='nas-top inverted' style={{display: "none"}}>
                <div className='nas-content'>
                    <div className='title-container'>
                        <h1>Self-Service tools</h1>
                        <img className='wifi' src={wifi} alt="Wifi logo"/>
                    </div>
                    <h3>Resolve Violations</h3>
                    <p>• Proactively address violations with step-by-step guidance and resources.</p>
                    <h3>Manage Registrations</h3>
                    <p>• Streamline the registration process with user-friendly tools and online forms.</p>
                </div>
                <div className='nas-content'>
                    <img src={alerts} alt='Violerts Alerts'/>
                </div>
            </div>
            <div className='nas-top'>
                <div className='nas-content'>
                    <div className='title-container'>
                        <h1>Compliance</h1>
                        <img className='wifi' src={wifi} alt="Wifi logo"/>
                    </div>

                    <p>Violerts isn't just about identifying violations; it's your partner in achieving and maintaining compliance. By offering real-time information, clear guidance, and access to solutions, Violerts empowers you to become a proactive property manager and navigate compliance with confidence.</p>

                </div>
                <div className='nas-content'>
                    <Link to="/property/130%20West%2037%20Street%2C%20New%20York%2C%20NY%2010018/overview"><img src={records} alt='Violerts Dashboard'/></Link>
                </div>
            </div>

        </div>
    );
}

export default ToolsAndCompliance;

